<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!permissionId">
          Add Permission
        </h1>
        <h1 v-if="permissionId">
          Edit Permission
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="permissions()"
        >
          Permissions
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!permissionId"
          title="Permission Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="permissionId"
          title="Permission Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
    <el-row :gutter="20">
        

      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Permission"
          prop="name"
        >
          <el-input
            v-model="data.name"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          <input
            id="permissionId"
            v-model="permissionId"
            name="permissionId"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!permissionId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>
<script>
import PermissionService from "@/api/permissions.service";
export default {
  data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Permission Name.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        permissionId: this.$route.params.permissionId,
         page: 1,
         search: '',
        array_products:[],
        data: {
          name: null,
        },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ]
        }
      };
    },
  
	mounted() {
    if(this.permissionId){
      this.getpermission();
    }
  },
    methods: {           
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.permissionId) {
             return PermissionService.add(this.data).then(response => {
                this.showAlertAdded=true ;
                return response;
              });
          } else if (valid && this.permissionId) {
            return PermissionService.update(this.permissionId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      permissions(){
        this.$router.push("/permissions");
      },      
      getpermission(){
        return PermissionService.get(this.permissionId).then(response => {
            this.data = response.data.data;
          });
      },
    }
  }
</script>
<style lang="scss">
.el-checkbox{
  width: 20%;
  text-transform: capitalize;
}
.el-select-dropdown{
      text-transform: capitalize;
}
.el-select .el-input__inner{
  text-transform: capitalize;
}
</style>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>