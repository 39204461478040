import BaseService from "./base.service";

class PermissionService {

    permissions(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('permissions', page, params);
    }

    get(id) {
        return BaseService.apiGetRequest('permissions/'+id);
    }
    delete(permission) {
		return BaseService.apiPostRequest('permissions/delete/', permission);
    }
    add(permission) {
        return BaseService.apiPostRequest('permissions/add', permission);
    }
    update(id, permission) {
        return BaseService.apiPostRequest('permissions/'+id, permission);
    }
}
export default new PermissionService();
